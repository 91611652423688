const exerciseType = {
  // Numeric to label mapping
  1: 'Repeats',
  2: 'Seconds',
  3: 'Lesson',
  // String to numeric mapping (for consistency)
  repeats: 1,
  seconds: 2,
  lesson: 3,
};

export default exerciseType;
