import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import { sortBy } from 'lodash';
import phase from '../../../constants/phase';
import dataProvider from '../../../providers/dataProvider';
import SessionExercisesTable from './SessionExercisesTable';
import { sumOfCol, convertToMinuts } from './utilsSessionExercise';
import SumCell from './SumCell';

const style = {
  sumCellTitle: {
    textAlign: 'right',
    backgroundColor: '#d4d4d4',
  },
  sumCellValue: {
    backgroundColor: '#d4d4d4',
  },
};

class SessionExercises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercisesAll: [],
      exercises: {
        prep: [],
        practice: [],
        play: [],
        push: [],
      },
      loading: false,
    };
  }

  componentDidMount() {
    this.getRelation();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.loading !== nextState.loading) {
      return false;
    }
    return true;
  }

  getRelation = () => {
    this.setState({ loading: true });
    dataProvider('GET_ALL', `sessions/${this.props.values.id}/relation`, {}).then((res) => {
      this.setState({ loading: false });
      this.setState({
        exercisesAll: res.data,
        exercises: {
          prep: sortBy(
            res.data.filter((item) => item.phase === 1),
            (item) => item.order,
          ),
          practice: sortBy(
            res.data.filter((item) => item.phase === 2),
            (item) => item.order,
          ),
          play: sortBy(
            res.data.filter((item) => item.phase === 3),
            (item) => item.order,
          ),
          push: sortBy(
            res.data.filter((item) => item.phase === 4),
            (item) => item.order,
          ),
        },
      });
    });
  };

  addExercises = async (event) => {
    this.setState({ loading: true });
    try {
      // Process exercises sequentially using reduce
      await event.reduce(
        (promise, item) =>
          promise.then(() =>
            dataProvider('CREATE', `sessions/${this.props.values.id}/relation`, {
              data: item,
            }),
          ),
        Promise.resolve(),
      );

      await this.getRelation();
    } finally {
      this.setState({ loading: false });
    }
  };

  addCircuit = (values) => {
    const { durationOptions } = this.props.values;
    /* eslint-disable no-param-reassign */
    values.type = 'circuit';
    values.timeMatrix = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in durationOptions) {
      values.timeMatrix[key.toString()] = 1;
    }
    /* eslint-enable no-param-reassign */

    this.setState({ loading: true });

    dataProvider('CREATE', `sessions/${this.props.values.id}/relation`, {
      data: values,
    }).finally(() => {
      this.getRelation();
      this.setState({ loading: false });
    });
  };

  updateCircuit = (circuitId, values) => {
    this.setState({ loading: true });

    const data = {
      name: values.name,
      restFor: values.restFor,
    };

    dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
      id: circuitId,
      data,
    }).finally(() => {
      this.getRelation();
      this.setState({ loading: false });
    });
  };

  updateCell = (cell) => {
    this.setState({ loading: true });
    // eslint-disable-next-line no-param-reassign
    cell.exerciseId = cell.exercise ? cell.exercise.id : null;
    dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
      id: cell.id,
      data: cell,
    }).then(() => {
      this.setState({ loading: false });
    });
  };

  handleDuplicate = (row) => {
    this.setState({ loading: true });
    dataProvider(
      'CREATE',
      `sessions/${this.props.values.id}/relation/${row.id}/duplicate`,
      {},
    ).finally(() => {
      this.getRelation();
      this.setState({ loading: false });
    });
  };

  handleDelete = (cell) => {
    this.setState({ loading: true });
    dataProvider('DELETE', `sessions/${this.props.values.id}/relation`, {
      id: cell.id,
    }).then(() => {
      this.setState({ loading: false });
      this.getRelation();
    });
  };

  handleUp = (row, list, index) => {
    this.setState({ loading: true });
    const exr1 = row;
    const exr2 = list[index - 1];
    const order = index + 1;
    exr1.order = order - 1;
    exr1.exerciseId = exr1.exercise ? exr1.exercise.id : null;
    exr2.order = order;
    exr2.exerciseId = exr2.exercise ? exr2.exercise.id : null;
    const requests = [
      dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
        id: exr1.id,
        data: exr1,
      }),
      dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
        id: exr2.id,
        data: exr2,
      }),
    ];
    Promise.all(requests).then(() => {
      this.setState({ loading: false });
      this.getRelation();
    });
  };

  handleDown = (row, list, index) => {
    this.setState({ loading: true });
    const exr1 = row;
    const exr2 = list[index + 1];
    const order = index + 1;
    exr1.order = order + 1;
    exr1.exerciseId = exr1.exercise ? exr1.exercise.id : null;
    exr2.order = order;
    exr2.exerciseId = exr2.exercise ? exr2.exercise.id : null;
    const requests = [
      dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
        id: exr1.id,
        data: exr1,
      }),
      dataProvider('UPDATE', `sessions/${this.props.values.id}/relation`, {
        id: exr2.id,
        data: exr2,
      }),
    ];
    Promise.all(requests).then(() => {
      this.setState({ loading: false });
      this.getRelation();
    });
  };

  handleUpdatePhaseDescription = (phaseId, description) => {
    dataProvider('UPDATE', `sessions/${this.props.values.id}/phase`, {
      id: phaseId,
      data: { description },
    });
  };

  getSummaryDuration = (cell, exercises, withVideo) => {
    return exercises
      .map((item) => {
        let sum = 0;
        // eslint-disable-next-line eqeqeq
        if (item.type == 'circuit') {
          sum = this.getSummaryDuration(cell, item.sessionExercises, withVideo);
          sum *= item.timeMatrix[cell] || 1;
          if (sum) {
            sum += item.restFor;
          }
        } else {
          sum = sumOfCol(item, cell);
          if (withVideo && item.exercise) {
            const { duration } = item.exercise.video;
            if (duration) {
              sum += parseInt(duration, 10);
            }
          }
        }
        return sum;
      })
      .reduce((acc, curent) => +acc + +curent, 0);
  };

  getPhaseDescription = (phaseId) => {
    const { phasesInfo } = this.props.values;
    if (phasesInfo && phasesInfo) {
      return phasesInfo.filter((item) => item.phase === phaseId)[0]?.description ?? undefined;
    }

    return undefined;
  };

  render() {
    const { values, classes } = this.props;
    const { exercises, loading, exercisesAll } = this.state;
    return (
      <>
        <Button onClick={this.getRelation} color="primary" className={classes.button}>
          Update Totals
        </Button>
        <SessionExercisesTable
          title="Prep"
          description={this.getPhaseDescription(phase.prepare)}
          exercises={exercises.prep}
          phase={phase.prepare}
          durationOptions={values.durationOptions}
          addExercises={this.addExercises}
          updateCell={this.updateCell}
          handleDelete={this.handleDelete}
          handleDuplicate={this.handleDuplicate}
          handleUp={this.handleUp}
          handleDown={this.handleDown}
          loading={loading}
          addCircuit={this.addCircuit}
          updateCircuit={this.updateCircuit}
          getSummaryDuration={this.getSummaryDuration}
          handleUpdatePhaseDescription={this.handleUpdatePhaseDescription}
        />
        <SessionExercisesTable
          title="Practice"
          description={this.getPhaseDescription(phase.practice)}
          exercises={exercises.practice}
          phase={phase.practice}
          durationOptions={values.durationOptions}
          addExercises={this.addExercises}
          updateCell={this.updateCell}
          handleDelete={this.handleDelete}
          handleDuplicate={this.handleDuplicate}
          handleUp={this.handleUp}
          handleDown={this.handleDown}
          loading={loading}
          addCircuit={this.addCircuit}
          updateCircuit={this.updateCircuit}
          getSummaryDuration={this.getSummaryDuration}
          handleUpdatePhaseDescription={this.handleUpdatePhaseDescription}
        />
        <SessionExercisesTable
          title="Play"
          description={this.getPhaseDescription(phase.play)}
          exercises={exercises.play}
          phase={phase.play}
          durationOptions={values.durationOptions}
          addExercises={this.addExercises}
          updateCell={this.updateCell}
          handleDelete={this.handleDelete}
          handleDuplicate={this.handleDuplicate}
          handleUp={this.handleUp}
          handleDown={this.handleDown}
          loading={loading}
          addCircuit={this.addCircuit}
          updateCircuit={this.updateCircuit}
          getSummaryDuration={this.getSummaryDuration}
          handleUpdatePhaseDescription={this.handleUpdatePhaseDescription}
        />
        <SessionExercisesTable
          title="Push"
          description={this.getPhaseDescription(phase.push)}
          exercises={exercises.push}
          phase={phase.push}
          durationOptions={values.durationOptions}
          addExercises={this.addExercises}
          updateCell={this.updateCell}
          handleDelete={this.handleDelete}
          handleDuplicate={this.handleDuplicate}
          handleUp={this.handleUp}
          handleDown={this.handleDown}
          loading={loading}
          addCircuit={this.addCircuit}
          updateCircuit={this.updateCircuit}
          getSummaryDuration={this.getSummaryDuration}
          handleUpdatePhaseDescription={this.handleUpdatePhaseDescription}
        />
        {values && values.durationOptions && this.state.exercisesAll.length ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '300px' }} className={classes.sumCellTitle}>
                  TOTAL SESSION EXERCISE TIME
                </TableCell>
                {Object.keys(values.durationOptions).map((cell, i) => {
                  const summaryDuration = this.getSummaryDuration(cell, exercisesAll);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell className={classes.sumCellValue} key={i} align="left">
                      <SumCell {...convertToMinuts(summaryDuration)} />
                    </TableCell>
                  );
                })}
                <TableCell className={classes.sumCellTitle} />
              </TableRow>
              <TableRow>
                <TableCell style={{ width: '300px' }} className={classes.sumCellTitle}>
                  TOTAL SESSION TIME INCLUDING VIDEO
                </TableCell>
                {Object.keys(values.durationOptions).map((cell, i) => {
                  const summaryDuration = this.getSummaryDuration(cell, exercisesAll, true);
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableCell className={classes.sumCellValue} key={i} align="left">
                      <SumCell {...convertToMinuts(summaryDuration)} />
                    </TableCell>
                  );
                })}
                <TableCell className={classes.sumCellTitle} />
              </TableRow>
            </TableBody>
          </Table>
        ) : null}
      </>
    );
  }
}

export default withStyles(style)(SessionExercises);
