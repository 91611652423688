/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
import React, { memo, useState, useEffect, useCallback, Fragment } from 'react';
import { SaveButton, UPDATE } from 'react-admin';
import {
  withStyles,
  Paper,
  Typography,
  List,
  ListItemText,
  ListItem,
  Grid,
  Button,
} from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { cloneDeep } from 'lodash';
import { Add } from '@material-ui/icons';
import SessionColumn from './SessionColumn';
import TrackColumn from './TrackColumn';
import TracksAnswers from './TracksAnswers';
import dataProvider from '../../../../providers/dataProvider';
import { trackType } from './constants';

const styles = {
  loader: {
    textAlign: 'center',
    padding: '100px 0',
    fontSize: 20,
  },
  trackContainer: {
    marginTop: 20,
    display: 'flex',
    gap: '20px',
    width: ' calc(100vw - 111px)',
    overflowX: 'auto',
    paddingBottom: 10,
  },
  save: {
    position: 'fixed',
    bottom: 20,
    right: 20,
  },
  questions: {
    marginTop: 20,
    padding: 10,
    maxWidth: '60%',
  },
  answerValue: {
    maxWidth: '50%',
    wordBreak: 'break-all',
  },
  newTrackWrapper: {
    paddingTop: 60,
  },
  newTrackBtn: {
    height: 70,
  },
};

const getNextSessionId = (tracks) => {
  const maxId = Math.max(...tracks.map((a) => Math.max(...a.sessions.map((b) => b.id))));
  return maxId > 0 ? maxId + 1 : 1;
};

const parseRawTrackList = (tracks, sessions) => {
  return tracks.map((track) => ({
    ...track,
    sessions: track.sessions.map((sessionTrack) => ({
      ...sessionTrack,
      name: sessions.find((session) => session.id === sessionTrack.sessionId)?.name,
    })),
  }));
};

const ProgramTrack = ({ classes, record: { id: programId } }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const [allSessions, setAllSessions] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [questions, setQuestion] = useState([]);
  const [nextTrackSessionId, setNextTrackSessionId] = useState(1);
  const [nextTrackId, setNextTrackId] = useState(1);

  const increaseNextTrackSessionId = useCallback(() => {
    setNextTrackSessionId((prev) => prev + 1);
  }, []);

  const increaseNextTrackId = useCallback(() => {
    setNextTrackId((prev) => prev + 1);
  }, []);

  // Get all data related to tracks
  const fetchData = useCallback(() => {
    Promise.all([
      dataProvider('GET_ALL', `admin/program-tracks/sessions/${programId}`),
      dataProvider('GET_ALL', `admin/program-tracks/${programId}`),
      dataProvider('GET_ALL', `admin/questions`, { urlParams: { program: programId } }),
    ]).then(([allSessionsRaw, tracksRaw, questionsRaw]) => {
      setAllSessions(allSessionsRaw.data);
      setTracks(parseRawTrackList(tracksRaw.data, allSessionsRaw.data));
      setNextTrackSessionId(getNextSessionId(tracksRaw.data));
      setNextTrackId(Math.max(...tracksRaw.data.map((i) => i.id)) + 1);
      setQuestion(questionsRaw.data.questions);
      setIsLoading(false);
    });
  }, [programId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleMove = useCallback(({ dragItem, targetItem }) => {
    setTracks((prev) =>
      prev.map((track) => {
        if (track.id !== dragItem.trackId && track.id !== targetItem.trackId) {
          return track;
        }

        // First collect all sessions in their new order
        const reorderedSessions = track.sessions.reduce((acc, i) => {
          if (i.id === dragItem.id) {
            return acc;
          }

          if (i.id === targetItem.id) {
            if (dragItem.trackId !== targetItem.trackId || dragItem.order > targetItem.order) {
              acc.push(dragItem, i);
            } else {
              acc.push(i, dragItem);
            }
          } else {
            acc.push(i);
          }

          return acc;
        }, []);

        // Then reassign orders sequentially
        return {
          ...track,
          sessions: reorderedSessions.map((session, index) => ({
            ...session,
            order: index + 1,
          })),
        };
      }),
    );
  }, []);

  const handleDrop = useCallback(({ dragItem, targetTrackId }) => {
    setTracks((prev) =>
      prev.map((track) => {
        if (track.id === dragItem.trackId && track.id !== targetTrackId) {
          // Remove session from source track and reorder remaining sessions
          return {
            ...track,
            sessions: track.sessions
              .filter((i) => i.id !== dragItem.id)
              .map((session, index) => ({
                ...session,
                order: index + 1,
              })),
          };
        }

        if (track.id !== targetTrackId) {
          return track;
        }

        // Add session to target track and ensure proper ordering
        const updatedSessions = [
          ...track.sessions.filter((i) => i.id !== dragItem.id),
          { ...dragItem },
        ];

        return {
          ...track,
          sessions: updatedSessions.map((session, index) => ({
            ...session,
            order: index + 1,
          })),
        };
      }),
    );
  }, []);

  const handleRename = useCallback(({ value, trackId }) => {
    setTracks((prev) =>
      prev.map((i) => {
        if (i.id !== trackId) {
          return i;
        }

        return { ...i, name: value };
      }),
    );
  }, []);

  const handleDeleteTrackSession = useCallback(({ id, trackId }) => {
    setTracks((prev) =>
      prev.map((track) => {
        if (track.id !== trackId) {
          return track;
        }

        const sessions = track.sessions.filter((i) => i.id !== id);

        return {
          ...track,
          sessions: sessions.map((session, index) => ({
            ...session,
            order: index + 1,
          })),
        };
      }),
    );
  }, []);

  const handleDeleteTrack = useCallback(({ trackId }) => {
    setTracks((prev) => prev.filter((track) => track.id !== trackId));
  }, []);

  const handleCopyTrack = useCallback(
    ({ trackId }) => {
      setTracks((prev) => {
        const trackCopy = cloneDeep(prev.find((i) => i.id === trackId));
        trackCopy.id = nextTrackId;
        increaseNextTrackId();
        trackCopy.name += '_copy';
        trackCopy.type = trackType;
        trackCopy.isNew = true;
        trackCopy.answers = [];
        trackCopy.sessions = trackCopy.sessions.map((a, b) => ({
          ...a,
          id: nextTrackSessionId + b,
          isNew: true,
        }));
        setNextTrackSessionId(trackCopy.sessions[trackCopy.sessions.length - 1].id + 1);

        return [...prev, trackCopy];
      });
    },
    [nextTrackId, increaseNextTrackId, nextTrackSessionId],
  );

  const handleNewTrack = useCallback(() => {
    setTracks((prev) => {
      const newTrack = {};
      newTrack.id = nextTrackId;
      increaseNextTrackId();
      newTrack.name = 'New track';
      newTrack.type = trackType;
      newTrack.isNew = true;
      newTrack.answers = [];
      newTrack.sessions = [];

      return [...prev, newTrack];
    });
  }, [nextTrackId, increaseNextTrackId]);

  const handleSaveTrackChanges = useCallback(() => {
    setIsLoading(true);
    dataProvider(UPDATE, `admin/program-tracks`, { data: tracks, id: programId })
      .then(() => {
        fetchData();
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e);
      });
  }, [programId, tracks, fetchData]);

  const removeAnswer = useCallback(
    (trackId, value) => {
      const tracksCopy = cloneDeep(tracks);

      const i = tracksCopy.findIndex((t) => t.id === trackId);
      const index = tracksCopy[i].answers.findIndex((a) => a.value === value);
      tracksCopy[i].answers.splice(index, 1);

      setTracks(tracksCopy);
    },
    [tracks],
  );

  const addNewAnswer = useCallback(
    (answer) => {
      const tracksCopy = cloneDeep(tracks);

      const track = tracksCopy.findIndex((t) => t.id === answer.programTrackId);
      tracksCopy[track].answers.push(answer.value);

      setTracks(tracksCopy);
    },
    [tracks],
  );

  if (isLoading) {
    return <div className={classes.loader}>Loading...</div>;
  }

  return (
    <div>
      <Paper className={classes.questions}>
        <Typography variant="headline">Questions</Typography>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            {!questions.length && <p>No questions</p>}
            <List>
              {questions.map((question) => (
                <Fragment key={`key${question.id}`}>
                  <ListItem>
                    <ListItemText primary={question.title} className={classes.answerValue} />
                  </ListItem>
                  {question.answers.map((answer) => (
                    <List dense disablePadding key={`key${answer.id}`}>
                      <ListItem button>
                        <ListItemText inset primary={answer.title} secondary={answer.value} />
                      </ListItem>
                    </List>
                  ))}
                </Fragment>
              ))}
            </List>
          </Grid>
          {!!questions.length && (
            <Grid item xs={6} style={{ paddingTop: 30 }}>
              <TracksAnswers
                tracks={tracks}
                removeAnswer={removeAnswer}
                addNewAnswer={addNewAnswer}
                questions={questions}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
      <div className={classes.trackContainer}>
        <Typography style={{ color: 'red' }}>{error}</Typography>
        <DndProvider backend={HTML5Backend}>
          <SessionColumn allSessions={allSessions} nextTrackSessionId={nextTrackSessionId} />
          {tracks.map((track) => (
            <TrackColumn
              key={track.id}
              track={track}
              handleMove={handleMove}
              handleDrop={handleDrop}
              increaseNextTrackSessionId={increaseNextTrackSessionId}
              handleRename={handleRename}
              handleDeleteTrackSession={handleDeleteTrackSession}
              handleDeleteTrack={handleDeleteTrack}
              handleCopyTrack={handleCopyTrack}
            />
          ))}
          <div className={classes.newTrackWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewTrack}
              className={classes.newTrackBtn}
            >
              <Add />
              New Track
            </Button>
          </div>
        </DndProvider>
      </div>
      <div className={classes.save}>
        <SaveButton onClick={handleSaveTrackChanges} />
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(ProgramTrack));
