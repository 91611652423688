import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ArrowUpward, ArrowDownward, ContentCopy, Delete, Edit } from '@material-ui/icons';
import grey from '@material-ui/core/colors/grey';
import CircuitModal from './CircuitModal';
import ChooseExercisesModal from './ChooseExercisesModal';
import { convertToMinuts } from './utilsSessionExercise';
import SumCell from './SumCell';
import exerciseType from '../../../constants/exerciseType';

const style = () => ({
  root: {
    margin: '20px 0 40px 0',
  },
  header: {
    width: '100%',
  },
  title: {
    display: 'inline-block',
    width: '100px',
    marginRight: '20px',
  },
  sumCellTitle: {
    textAlign: 'right',
    backgroundColor: grey[300],
  },
  sumCellValue: {
    backgroundColor: grey[300],
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
  },
  table: {
    backgroundColor: grey[300],
  },
  tableRow: {
    backgroundColor: grey[100],
  },
  tableCell: {
    padding: '4px 56px 4px 24px',
  },
  menuTableCell: {
    whiteSpace: 'nowrap',
  },
  circuitExerciseTableRow: {
    backgroundColor: grey[50],
  },
  circuitExerciseTableCell: {
    paddingLeft: '32px',
  },
  orderCell: {
    width: '40px',
    padding: '4px 8px',
  },
});

class SessionExercisesTable extends PureComponent {
  defaultCircuit = {
    name: '',
    restFor: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      openAddExercise: false,
      defaultPhaseDescription: !this.props.description,
      customPhaseDescription: this.props.description ?? '',
      showCircuitModal: false,
      durationOptions: Object.keys(this.props.durationOptions),
      circuit: this.getDefaultCircuit(),
    };
  }

  getDefaultCircuit() {
    return { ...this.defaultCircuit };
  }

  handleClickAddExercise = () => {
    this.setState({ openAddExercise: true });
  };

  handleCloseExercisesModal = () => {
    this.setState({ openAddExercise: false });
  };

  handleClickAddCircuit = () => {
    this.setState({
      showCircuitModal: true,
      circuit: this.getDefaultCircuit(),
    });
  };

  handleClickEditCircuit = (item) => {
    this.setState({
      showCircuitModal: true,
      circuit: { ...item },
    });
  };

  handleCloseCircuitModal = () => {
    this.setState({ showCircuitModal: false });
  };

  handleAction = (action, row, list, index) => {
    if (action && action.toLowerCase() === 'delete') {
      this.props.handleDelete(row);
    }
    if (action && action.toLowerCase() === 'move up') {
      this.props.handleUp(row, list, index);
    }
    if (action && action.toLowerCase() === 'move down') {
      this.props.handleDown(row, list, index);
    }
    if (action && action.toLowerCase() === 'duplicate') {
      this.props.handleDuplicate(row);
    }
    if (action && action.toLowerCase() === 'edit') {
      this.handleClickEditCircuit(row);
    }

    if (action && action.toLowerCase() === 'update_phase_description') {
      this.props.handleUpdatePhaseDescription(
        this.props.phase,
        this.state.defaultPhaseDescription ? undefined : this.state.customPhaseDescription,
      );
    }
  };

  getLastExer = () => {
    const exers = this.props.exercises;
    return exers[exers.length - 1].order;
  };

  getMaxOrder = (items) => {
    if (!items) {
      return 0;
    }
    return items
      .map((item) => item.order)
      .sort()
      .reduce((acc, cur) => {
        // eslint-disable-next-line no-param-reassign
        acc = cur > acc ? cur : acc;
        return acc;
      }, 0);
  };

  handleAddExercises = (event, circuitId) => {
    const { durationOptions } = this.state;
    const { phase } = this.props;

    const items = event.map((item) => {
      const timeMatrix = durationOptions.reduce((acc, current) => {
        // Set default value of 1 for lesson-type exercises
        acc[current] = item.type === 3 ? 1 : 0;
        return acc;
      }, {});

      return {
        circuitId: circuitId || null,
        exerciseId: item.id,
        phase,
        timeMatrix,
      };
    });

    this.props.addExercises(items);
  };

  renderTableHeader = () => {
    const { classes } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell className={classes.orderCell}>Order</TableCell>
          <TableCell>Exercises</TableCell>
          {this.state.durationOptions.map((item, i) => {
            // eslint-disable-next-line react/no-array-index-key
            return <TableCell key={i}>{item} Minutes</TableCell>;
          })}
          <TableCell>
            {this.props.loading ? <CircularProgress className={classes.loader} /> : null}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderTimeMatrixInput = (item, cell) => {
    const { loading, updateCell } = this.props;

    // Hide input for lesson-type exercises
    if (item.exercise?.type === 3) {
      // 3 is Lesson type
      return <TextField disabled defaultValue="video runtime" />;
    }

    let label = 'Seconds';
    // eslint-disable-next-line eqeqeq
    if (item.type == 'ordinary') {
      label = exerciseType[item.exercise.type] + (item.exercise.forEachSide ? ' per side' : '');
      // eslint-disable-next-line eqeqeq
    } else if (item.type == 'circuit') {
      label = 'Repeat';
    }
    return (
      <>
        <InputLabel>{label}</InputLabel>
        <Input
          type="number"
          disabled={loading}
          defaultValue={item.timeMatrix[cell] || 0}
          onBlur={(event) => {
            // eslint-disable-next-line no-param-reassign
            item.timeMatrix[cell] = +event.target.value;
            updateCell(item);
          }}
        />
      </>
    );
  };

  renderCircuitExercisesRows = (item) => {
    // eslint-disable-next-line eqeqeq
    if (item.type == 'circuit') {
      return item.sessionExercises.map((exercise, i) => {
        // eslint-disable-next-line no-param-reassign
        exercise.circuitId = item.id;
        return this.renderTableRow(exercise, i, item.sessionExercises);
      });
    }
    return null;
  };

  renderMenu = (item, pi, circuitId, exercises) => {
    /* eslint-disable eqeqeq */
    const first = pi == 0;
    const last = pi == exercises.length - 1;
    const color = circuitId ? 'primary' : 'secondary';
    const showDuplicateButton = item.type == 'ordinary';
    const showEditButton = item.type == 'circuit';
    /* eslint-enable eqeqeq */

    return (
      <>
        {showDuplicateButton && (
          <IconButton onClick={() => this.handleAction('duplicate', item)}>
            <ContentCopy />
          </IconButton>
        )}
        {showEditButton && (
          <IconButton onClick={() => this.handleAction('edit', item)}>
            <Edit />
          </IconButton>
        )}
        <IconButton
          disabled={first}
          onClick={() => this.handleAction('move up', item, exercises, pi)}
        >
          <ArrowUpward color={first ? 'disabled' : color} />
        </IconButton>
        <IconButton
          disabled={last}
          onClick={() => this.handleAction('move down', item, exercises, pi)}
        >
          <ArrowDownward color={last ? 'disabled' : color} />
        </IconButton>
        <IconButton onClick={() => this.handleAction('delete', item, exercises, pi)}>
          <Delete />
        </IconButton>
      </>
    );
  };

  renderTableRow = (item, pi, exercises) => {
    const { classes } = this.props;
    const rowClass = item.circuitId ? classes.circuitExerciseTableRow : classes.tableRow;
    const cellClass = item.circuitId ? classes.circuitExerciseTableCell : classes.tableCell;
    let symbol = '➛';
    if (item.circuitId) {
      symbol = '⤷';
    }
    let suffix = '';
    // eslint-disable-next-line eqeqeq
    if (item.type == 'circuit') {
      symbol = '↻';
      suffix = (
        <span>
          <br />
          Rest for: {item.restFor}
        </span>
      );
    }
    return (
      <TableRow key={item.id} classes={{ root: rowClass }}>
        <TableCell classes={{ root: `${cellClass} ${classes.orderCell}` }}>{item.order}</TableCell>
        <TableCell classes={{ root: cellClass }}>
          {symbol} {(item.exercise && item.exercise.name) || item.name} {suffix}
        </TableCell>
        {Object.keys(item.timeMatrix).map((cell, ci) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={ci + Date.now()} classes={{ root: classes.tableCell }}>
            <FormControl>{this.renderTimeMatrixInput(item, cell, item.circuitId)}</FormControl>
          </TableCell>
        ))}
        <TableCell classes={{ root: classes.menuTableCell }}>
          {this.renderMenu(item, pi, item.circuitId, exercises)}
        </TableCell>
      </TableRow>
    );
  };

  renderTableBody = () => {
    const { exercises } = this.props;
    return (
      <TableBody>
        {exercises.map((item, pi) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={pi + +Date.now()}>
            {this.renderTableRow(item, pi, exercises)}
            {this.renderCircuitExercisesRows(item, pi)}
          </React.Fragment>
        ))}
      </TableBody>
    );
  };

  renderTableFooter = () => {
    const { classes, exercises, getSummaryDuration } = this.props;
    const durationOptions = this.props.durationOptions || {};
    return (
      <TableBody>
        <TableRow>
          <TableCell className={classes.orderCell} />
          <TableCell className={classes.sumCellTitle}>TOTAL EXERCISE TIME</TableCell>
          {Object.keys(durationOptions).map((cell, i) => {
            const summaryDuration = getSummaryDuration(cell, exercises);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell key={i} className={classes.sumCellValue}>
                <SumCell {...convertToMinuts(summaryDuration)} />
              </TableCell>
            );
          })}
          <TableCell className={classes.sumCellTitle} />
        </TableRow>
        <TableRow>
          <TableCell className={classes.orderCell} />
          <TableCell className={classes.sumCellTitle}>TOTAL TIME INCLUDING VIDEO</TableCell>
          {this.state.durationOptions.map((cell, i) => {
            const summaryDuration = getSummaryDuration(cell, exercises, true);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell key={i} className={classes.sumCellValue}>
                <SumCell {...convertToMinuts(summaryDuration)} />
              </TableCell>
            );
          })}
          <TableCell className={classes.sumCellTitle} />
        </TableRow>
      </TableBody>
    );
  };

  renderTable = () => {
    const { classes } = this.props;
    const { exercises } = this.props;
    if (!exercises.length) {
      return null;
    }
    return (
      <Table classes={{ root: classes.table }}>
        {this.renderTableHeader()}
        {this.renderTableBody()}
        {this.renderTableFooter()}
      </Table>
    );
  };

  addCircuit = (values) => {
    const { addCircuit, exercises, phase } = this.props;
    /* eslint-disable no-param-reassign */
    values.order = this.getMaxOrder(exercises) + 1;
    values.phase = phase;
    /* eslint-enable no-param-reassign */
    addCircuit(values);
  };

  updateCircuit = (id, values) => {
    const { updateCircuit } = this.props;
    updateCircuit(id, values);
  };

  renderCircuitModal = () => {
    const { circuit } = this.state;
    if (!this.state.showCircuitModal) {
      return null;
    }
    return (
      <CircuitModal
        open={this.state.showCircuitModal}
        addCircuit={this.addCircuit}
        updateCircuit={this.updateCircuit}
        handleClose={this.handleCloseCircuitModal}
        circuit={circuit}
      />
    );
  };

  render() {
    const { title, classes, exercises } = this.props;
    const { openAddExercise } = this.state;
    // eslint-disable-next-line eqeqeq
    const circuits = exercises.filter((item) => item.type == 'circuit');
    return (
      <div className={classes.root}>
        <Grid container direction="row" alignItems="center" style={{ marginBottom: 24 }}>
          <Grid
            container
            xs={12}
            direction="row"
            alignItems="center"
            justify="space-between"
            spacing={8}
          >
            <Grid item xs={3}>
              <h4 className={classes.title}>{title}</h4>
            </Grid>
            <Grid
              container
              xs={4}
              spacing={8}
              direction="row"
              alignItems="center"
              justify="flex-end"
            >
              <Grid item>
                <Button variant="outlined" color="primary" onClick={this.handleClickAddExercise}>
                  Add exercise
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" onClick={this.handleClickAddCircuit}>
                  Add circuit
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            direction="row"
            alignItems="center"
            justify="space-between"
            spacing={16}
          >
            <Grid item xs={12}>
              <TextField
                label="Phase description"
                disabled={this.state.defaultPhaseDescription}
                defaultValue={this.state.customPhaseDescription}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) =>
                  this.setState({
                    customPhaseDescription: e.target.value,
                  })
                }
                placeholder="Put your custom phase description here"
                fullWidth
                margin="normal"
              />
            </Grid>

            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Switch
                    name="checkedB"
                    color="primary"
                    checked={this.state.defaultPhaseDescription}
                    onClick={() => {
                      this.setState((prevState) => ({
                        defaultPhaseDescription: !prevState.defaultPhaseDescription,
                      }));
                    }}
                  />
                }
                label="Use default description"
              />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={() => this.handleAction('update_phase_description', 'update')}>
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {openAddExercise ? (
          <ChooseExercisesModal
            exercises={exercises}
            circuits={circuits}
            open={openAddExercise}
            handleClose={this.handleCloseExercisesModal}
            handleAddExercises={this.handleAddExercises}
          />
        ) : null}
        {this.renderCircuitModal()}
        {this.renderTable()}
      </div>
    );
  }
}

export default withStyles(style)(SessionExercisesTable);
